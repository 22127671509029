<template>
  <div
    v-if="data.series.length > 0"
    class="d-flex flex-column"
  >
    <vue-apex-charts
      class="py-5 px-1"
      width="100%"
      height="200"
      type="donut"
      :options="data.options"
      :series="data.series"
    />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import VueApexCharts from 'vue-apexcharts'
import { mapGetters } from 'vuex'

export default {
  name: 'ManagerGraph',
  middleware: ['auth'],

  components: {
    VueApexCharts
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },

  data () {
    return {

    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },

  mounted () {
    core.index()
  },

  methods: {
    viewMore (status) {
      return {
        path: `/${this.user?.role}/view-more/${status}`
      }
    }
  }

}
</script>
