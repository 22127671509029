<template>

  <b-card>

    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <label for="regions">Filter By Regions</label>
          <v-select
            id="regions"
            v-model="filterGraph.region"
            :options="list.regions"
            label="region_name"
            placeholder="-- Please Select Region --"
          >
            <template #option="{ region_name }">
              <span>{{ region_name }}</span>
            </template>
            <template #no-options="">
              No Available Region
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <label for="provinces">Filter By Province</label>
          <v-select
            id="provinces"
            v-model="filterGraph.province"
            :options="list.provinces"
            label="province_name"
            placeholder="-- Please Select Province --"
          >
            <template #option="{ province_name }">
              <span>{{ province_name }}</span>
            </template>
            <template #no-options="">
              No Available Province
            </template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        class="mt-5"
      >
        <Graph
          :data="dataRaisers"
          class="my-2"
        />
      </b-col>
    </b-row>

    <section class="mt-5">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>
              Region/Province
            </th>
            <th
              colspan="5"
              class="text-center"
            >
              Major raiser
            </th>

          </tr>
        </thead>
        <tbody
          v-for="(region, regionKey) in list.data"
          :key="regionKey"
        >
          <tr
            :key="regionKey"
            aria-colspan="6"
            style="border-bottom: 1px solid  #dee2e6;"
          >

            <td colspan="6">
              <strong>{{ region.region_name }}</strong>
            </td>

          </tr>
          <tr
            v-for=" (province, provinceKey) in region.provinces"
            :key="`provinces-${provinceKey}`"
            style="border-bottom: 1px solid  #dee2e6;"
          >
            <td>
              {{ province.province_name }}
            </td>

            <td
              v-for=" (raiser, raiserKey) in province.raisers"
              :key="`raiser-${raiserKey}`"
            >
              {{ raiser.raiser_name }}
            </td>
          </tr>
        </tbody>
      </table>

    </section>
  </b-card>

</template>
<script>

import { SharedStatisticsService, SharedListService } from '@/services'
import Graph from '@/components/dashboard/Graph'

const year = '2023'

const options = {
  series: [],
  labels: [],
  legend: {
    show: true
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return Number(val).toFixed(2) + '%'
    }
  }

}

export default {
  name: 'ManagerHerbicides',
  middleware: ['auth', 'manager', 'supervisor'],
  components: {
    Graph
  },

  data () {
    return {
      list: {
        regions: [],
        provinces: [],
        data: []
      },
      selected: {
        region: '2',
        province: '7'
      },
      filterGraph: {
        region: { id: 2, region_name: 'REGION II' },
        province: { id: 7, province_name: 'ISABELA' }
      },

      dataRaisers: {
        title: 'Herbicides',
        year: year,
        series: [],
        options: options
      }
    }
  },

  watch: {
    'filterGraph.region' (value) {
      if (value) {
        this.selected.region = value?.id || null
        this.filterGraph.province = null
        this.selected.province = null
        this.getProvinces(value?.id || null)
        this.getStatistics()
      }
    },
    'filterGraph.province' (value) {
      if (value) {
        this.selected.province = value?.id || null
        this.getStatistics()
      }
    }
  },

  mounted () {
    this.getStatistics()
    this.getRegions()
    this.getProvinces(this.selected.region)
  },

  methods: {
    async getStatistics () {
      const { data } = await SharedStatisticsService.getGraphRaisers(`region=${this.selected.region}&province=${this.selected.province}`)

      // herbicides
      this.dataRaisers.series = data?.total_count || []
      this.dataRaisers.options = { ...this.dataRaisers.options, labels: data?.data }
      this.list.data = data?.raisers
    },

    async getRegions () {
      const { data } = await SharedListService.getRegions()
      this.list.regions = data
    },
    async getProvinces (regionId) {
      this.selected.province = null
      if (regionId) {
        await SharedListService.getProvinces(`region_id=${regionId}`).then(
          ({ data }) => {
            this.list.provinces = data
          }
        )
      }
    },

    graphChange () {
      this.getStatistics()
    }
  }
}
</script>
